import React from "react";
import PropTypes from "prop-types"
import "./_checkbox.scss"
import {myID} from "../../../libs/generateID";

export default class Checkbox extends React.Component {

    onChange = e => {
        this.props.onChange(e.target.checked, this.props);
    };

    render() {
        const {label, id=myID(), disabled, checked, readOnly, required, divRef} = this.props;
        return (
            <div className={"fetura-checkbox"} ref={divRef}>
                <input disabled={disabled} type="checkbox" id={id} onChange={this.onChange} checked={checked}
                       readOnly={readOnly}/>
                <label style={readOnly ? {pointerEvents: 'none'} : {}} htmlFor={id}>{label && '\u00A0'}{label}{required && <sup>*</sup>}</label>
            </div>
        );
    }

}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    divRef: PropTypes.object
};

Checkbox.defaultProps = {
    onChange: (value) => {
    },
    // id: myID(),
    required: false,
    disabled: false
};
