import React from "react";
import PropTypes from "prop-types"
import XLSX from "../../../beans/XLSX/XLSX";
import Button from "../button/Button";

export default class SaveAsExcell extends React.Component {

    onSaveClick = () => {
        const {Xaxis, dataDef, data, fileName} = this.props;
        let headers = [
            {
                name: Xaxis.name,
                field: Xaxis.dataKey,
                excelFormatter: Xaxis.formatter
            },
        ];
        for (let definition of dataDef) {
            let tmp = {
                name: definition.name,
                field: definition.dataKey
            };
            definition.unit && (tmp.name = `${tmp.name} (${definition.unit})`);
            headers.push(tmp);
        }

        let formatData = XLSX.formatData(headers, data);
        let xlsx = new XLSX(formatData, {
            headers
        });
        xlsx.save(fileName);
    };

    render() {
        return (
            <div className={"save-as-excell-container"}>
                <Button className={"text"} buttonColor={"secondary"} buttonStyle={"round"} type={"button"}
                        onClick={this.onSaveClick} icon={<i className="fas fa-file-export"/>}/>
            </div>
        );
    }

}

SaveAsExcell.propTypes = {
    Xaxis: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataKey: PropTypes.string.isRequired
    }).isRequired,
    data: PropTypes.array.isRequired,
    dataDef: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataKey: PropTypes.string.isRequired,
        unit: PropTypes.string
    }))
};
