import React from "react";
import PropTypes from "prop-types"
import {CircularProgressbar as Circle} from "react-circular-progressbar"
import {getColorForPercentage} from "../../../utils/ColorUtils";
import "./_circular-progressbar.scss"

export default class CircularProgressBar extends React.PureComponent {

    render() {
        const {percentage, text, monoColor, color} = this.props;
        return (
            <Circle value={percentage || 0} text={text || ""}
                    className={`fetura-circular-progressbar${monoColor ? " mono" : ""} ${monoColor ? color : getColorForPercentage(percentage)}`}/>
        );
    }

}

CircularProgressBar.propTypes = {
    percentage: PropTypes.number.isRequired,
    text: PropTypes.string,
    monoColor: PropTypes.bool,
    color: PropTypes.string
};

CircularProgressBar.defaultProps = {
    monoColor: false,
    color: "white"
};