import React, {Component} from 'react';
import Button from "../button/Button";
import "./_button-input.scss"

class ButtonInput extends Component {

    onButtonClick(value) {
        this.props.input.onChange(value);
    }

    render() {
        const {input: {value}, buttons, label, meta: {error, touched}} = this.props;
        return (
            <div className={`fetura-button-input${error ? " error" : ""}`}
                 data-tip={touched && error ? error : ""}>
                <span>{label}</span>
                <div className="button-container">
                    {
                        buttons.map((row, i) =>
                            <Button onClick={() => this.onButtonClick(row.value)}
                                    buttonColor={value === row.value ? "success" : ""} key={i}>{row.name}</Button>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default ButtonInput;