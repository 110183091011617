import React from "react";
import "./_card.scss"
import PropTypes from "prop-types";

const types = [50, 100, 200, 300, 400, 500, 600, 700, 800];

export default class Card extends React.Component {

    onContextMenu = e => {
        if (this.props.onContextMenu) {
            e.persist();
            e.preventDefault();
            this.props.onContextMenu(e);
        }
    };

    getSelectedDensity = () => {
        const {colorDensity} = this.props;
        const colorIndex = (types.findIndex(o => o === colorDensity) + 5) % types.length;
        return types[colorIndex];
    };

    render() {
        const {children, onClick, colorDensity, type, shadow, className, style, forwardRef, id, selected} = this.props;
        const cName = ["fetura-card", onClick ? "pointer" : "", `type-${type}`, `color-${selected ? this.getSelectedDensity() : colorDensity}`, className, !shadow ? 'no-shadow' : ''].filter(o => o).join(" ");
        return (
            <div
                style={style}
                className={cName}
                onClick={onClick} ref={forwardRef} id={id} onContextMenu={this.onContextMenu}>
                {children}
            </div>
        );
    }

}

Card.propTypes = {
    type: PropTypes.oneOf(["col", "container", "row"]),
    colorDensity: PropTypes.oneOf(types),
    shadow: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    forwardRef: PropTypes.object,
    id: PropTypes.string,
    onContextMenu: PropTypes.func,
    selected: PropTypes.bool
};

Card.defaultProps = {
    colorDensity: 50,
    type: "col",
    shadow: true,
    className: "",
    style: {}
};
