import React from "react";
import PropTypes from "prop-types"
import moment from "moment";
import {withTranslation} from "react-i18next";

class DateItem extends React.Component {

    onClick = () => {
        this.props.onClick(this.props.date);
    };

    getWeekDayName(date) {
        const {t} = this.props;
        switch (date.isoWeekday()) {
            case 1:
                return t("weekDays.mon");
            case 2:
                return t("weekDays.tue");
            case 3:
                return t("weekDays.wed");
            case 4:
                return t("weekDays.thu");
            case 5:
                return t("weekDays.fri");
            case 6:
                return t("weekDays.sat");
            case 7:
                return t("weekDays.sun");
            default:
                return "";
        }
    }

    render() {
        const {date, transform, selected} = this.props;
        let myDay = this.getWeekDayName(date);
        return (
            <div className={selected ? "date-item selected" : "date-item"}
                 style={{transform: `translate(-${transform}%, 0)`}} onClick={this.onClick}>
                <div className="date-item-content">
                    <div className="date-title">
                        {date.format("DD.MM")}
                    </div>
                    <div className="date-text">
                        {/*{date.format("ddd")}*/}
                        {myDay}
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(DateItem);

DateItem.propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    transform: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool
};