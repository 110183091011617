import React from "react";
import "./_date-chooser.scss"
import DateItem from "./DateItem";
import moment from "moment";
import PropTypes from "prop-types"
import Button from "../button/Button";

export default class DateChooser extends React.Component {

    constructor(props) {
        super(props);

        let dates = [];
        for (let i = 0; i < 6; i++) {
            dates.push(props.startingDate.clone().add(i, "days"))
        }
        this.state = {
            dates,
            transform: 0,
            clickedRight: 0
        }
    }

    onClickRight = () => {
        let {dates, transform, clickedRight} = this.state;
        let datesCopy = dates.slice(0);
        if (transform === clickedRight) {
            datesCopy.push(datesCopy[datesCopy.length - 1].clone().add(1, "day"));
            clickedRight++;
        }
        this.setState({
            dates: datesCopy,
            transform: transform + 1,
            clickedRight: clickedRight
        })
    };

    onClickLeft = () => {
        this.setState(state => ({
            transform: state.transform - 1
        }))
    };

    onItemClick = (date) => {
        let {selectedDates} = this.props;
        let selectedDatesCopy = selectedDates.slice(0);
        let hasDate = !!selectedDatesCopy.find(item => item.isSame(date, "day"));
        if (!hasDate) {
            selectedDatesCopy.push(date.clone());
        } else {
            selectedDatesCopy = selectedDatesCopy.filter(item => !item.isSame(date, "day"));
        }
        this.props.onChange(selectedDatesCopy);
    };

    isSelected(date) {
        let {selectedDates} = this.props;
        return !!selectedDates.find(item => item.isSame(date, "day"));
    }

    render() {
        const {dates, transform} = this.state;
        return (
            <div className="date-chooser">
                <div className="arrow left">
                    <Button buttonStyle="round" buttonColor="secondary" icon={<i className="fas fa-chevron-left"/>}
                            onClick={this.onClickLeft} type="button" disabled={transform === 0}/>
                </div>
                <div className="carousel-container">
                    <div className="date-carousel">
                        {
                            dates.map((date, index) => <DateItem date={date} key={index} transform={transform * 100}
                                                                 onClick={this.onItemClick}
                                                                 selected={this.isSelected(date)}/>)
                        }
                    </div>
                </div>
                <div className="arrow right">
                    <Button buttonStyle="round" buttonColor="secondary" icon={<i className="fas fa-chevron-right"/>}
                            onClick={this.onClickRight} type="button"/>
                </div>
            </div>
        );
    }

}

DateChooser.propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedDates: PropTypes.array.isRequired,
    startingDate: PropTypes.instanceOf(moment)
};

DateChooser.defaultProps = {
    onChange: () => {
    },
    startingDate: moment()
};