import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import _ from "lodash";

const TimeDef = {
    second: {
        timestamp: 1000,
        name: "second"
    },
    minute: {
        timestamp: 60 * 1000,
        name: "minute"
    },
    hour: {
        timestamp: 60 * 60 * 1000,
        name: "hour"
    },
    day: {
        timestamp: 24 * 60 * 60 * 1000,
        name: "day"
    },
    month: {
        timestamp: 30 * 24 * 60 * 60 * 1000,
        name: 'month'
    },
    year: {
        timestamp: 365 * 24 * 60 * 60 * 1000,
        name: 'year'
    }
};


@connect((store) => {
    return {
        locale: store.language.locale,
    };
})
    /**
     * TODO: DODAC INTERVAL JAKBY JAKIS DZBAN SIEDZIAL CALY DZIEN NA JEDNYM WIDOKU (?) I CZEKAL AZ MU PODSKOCZY WIEK SWINI
     */
class AgeCounter extends Component {

    constructor(props) {
        super(props);
        const formatter = AgeCounter.getFormatter(props.locale);
        this.state = {
            type: props.type,
            formatter: formatter,
            locale: props.locale,
            value: AgeCounter.getTime({...props, formatter})
        }
    }

    static getTime = ({show = [], time = null, formatter, start, stop, stopDefaultCurrentTime, type} = {}) => {
        const result = [];
        if (type === "fromDate") {
            if (!_.isFinite(start)) {
                return null;
            }
            if (!_.isFinite(stop)) {
                if (!stopDefaultCurrentTime) {
                    return null;
                } else {
                    stop = +new Date()
                }
            }
            time = Math.abs(start - stop);
        }
        if (time === null) {
            return null
        }
        show.forEach(key => {
            const ts = TimeDef[key].timestamp;
            const name = TimeDef[key].name;
            const value = Math.floor(time / ts);
            if (show.includes(key)) {
                time = time % ts;
                if (value) {
                    const tmp = formatter(value, name, "", +new Date(), () => {
                    }, () => +new Date());
                    result.push(value === 1 ? `1 ${tmp}` : tmp);
                }
            }
        });
        return result.join(" ")

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.locale !== prevState.locale) {
            const formatter = AgeCounter.getFormatter(nextProps.locale);
            return {
                locale: nextProps.locale,
                formatter: formatter,
                value: AgeCounter.getTime({...nextProps, formatter})
            }
        }
        const newValue = AgeCounter.getTime({...nextProps, formatter: prevState.formatter});
        if (!_.isEqual(prevState.value, newValue)) {
            return {value: newValue}
        }
        return null;
    }

    static getFormatter(locale = "en") {
        let strings;
        try {
            strings = require(`react-timeago/lib/language-strings/${locale}`).default
        } catch (e) {
            strings = require(`react-timeago/lib/language-strings/en`).default
        }
        return buildFormatter(strings)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState)
    }

    render() {
        const {container} = this.props;
        const {value} = this.state;
        return container(value)
    }
}

AgeCounter.propTypes = {
    container: PropTypes.func,
    start: PropTypes.number,
    stop: PropTypes.number,
    time: PropTypes.number,
    stopDefaultCurrentTime: PropTypes.bool,
    type: PropTypes.oneOf("fromTime", "fromDate")
};

AgeCounter.defaultProps = {
    container: (value) => <>{value ? value : "-"}</>,
    stopDefaultCurrentTime: true,
    type: "fromDate",
    show: ["month", "day"]
};

export default AgeCounter;