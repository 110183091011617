import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from "react-transition-group";
import "./_bottom-drawer.scss"
import ReactDOM from "react-dom";
import _ from "lodash";
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../../utils/DOMUtils";

class BottomDrawer extends PureComponent {
    toggleDrawer = _.debounce(() => {
        const {expanded} = this.state;
        if (expanded) {
            this.handleShrink()
        } else {
            this.handleExpand();
        }
    }, 20);

    constructor(props) {
        super(props);
        this.handleExpand = this.handleExpand.bind(this);
        this.handleShrink = this.handleShrink.bind(this);
        this.state = {
            expanded: false
        }
    }

    handleExpand() {
        this.setState({
            expanded: true
        })
    }

    handleShrink() {
        this.setState({
            expanded: false
        })
    }

    componentWillUnmount() {
        this.unlockScroll()
    }

    lockScroll = () => {
        bodyLockScroll(ScrollLock.DRAWER);
    };

    unlockScroll = () => {
        bodyUnlockScroll(ScrollLock.DRAWER);
    };

    render() {
        const {text, children, show} = this.props;
        const {expanded} = this.state;
        return ReactDOM.createPortal(
            <CSSTransition
                in={show}
                classNames="drawer-in"
                mountOnEnter
                unmountOnExit
            >
                <div className={"fetura-bottom-drawer"}>
                    <CSSTransition
                        in={expanded}
                        classNames="show"
                        onEntered={this.lockScroll}
                        onExited={this.unlockScroll}
                    >
                        <div className={"d-content"}>
                            <div className={"d-text"} onClick={this.toggleDrawer}>
                                <h5><i className={"fas fa-fw fa-arrow-up mr-1"}/>{text}</h5>
                            </div>
                            <div className={"d-items"}>
                                {children}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </CSSTransition>,
            document.getElementById("center-content") || document.createElement("div"));
    }
}

BottomDrawer.propTypes = {
    text: PropTypes.string.isRequired
};

BottomDrawer.defaultProps = {
    show: false
};

export default BottomDrawer;