import React from "react";
import PropTypes from "prop-types"
import "./_button.scss"

const AllowedExtraProps = [
    'onMouseEnter',
    'onMouseLeave',
    'title',
    'onTouchStart',
    'onTouchEnd',
    'onContextMenu',
    'onMouseDown',
    'onMouseUp'
];

export default class Button extends React.PureComponent {

    onClick = (e) => {
        const {stopPropagation, onClick} = this.props;
        e.persist();
        if (stopPropagation) {
            e.stopPropagation();
        }
        onClick(e);

    };

    render() {
        const {title, children, type, buttonStyle, buttonColor, disabled, icon, fixed, isLoading, className, buttonRef, text, selected, style} = this.props; //onHover
        const cName = `fetura-button ${buttonStyle} ${buttonColor} ${className} ${fixed ? 'fixed' : ''} ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}${!icon && isLoading ? " loading-animation" : ""}`;
        const iconClassName = `icon-container ${children ? 'has-children' : ''}`;
        const extraProps = Object.keys(this.props)
            .filter(key => AllowedExtraProps.includes(key))
            .reduce((obj, key) => {
                obj[key] = this.props[key];
                return obj;
            }, {});
        return (
            <button {...extraProps} title={title} disabled={isLoading || disabled} className={cName}
                    onClick={e => this.onClick(e)}
                    type={type} ref={buttonRef} style={style}>{icon ? <span className={iconClassName}>{isLoading ?
                <i className={"fas fa-fw fa-spin fa-spinner"}/> : icon}</span> : null}{children || text}</button>
        );
    }

}

Button.propTypes = {
    buttonStyle: PropTypes.oneOf(["round", "text", "bordered", ""]),
    buttonColor: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", "white", ""]),
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    loadingText: PropTypes.node,
    type: PropTypes.string,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.string,
    buttonRef: PropTypes.func,
    onHover: PropTypes.func,
    stopPropagation: PropTypes.bool,
    selected: PropTypes.bool,
    style: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    title: PropTypes.string
};

Button.defaultProps = {
    buttonStyle: "",
    buttonColor: "",
    disabled: false,
    onClick: () => {
    },
    isLoading: false,
    loadingText: <i className="fas fa-circle-notch fa-spin"/>,
    type: "submit",
    className: "",
    text: "",
    buttonRef: null,
    stopPropagation: false,
    selected: false
};
