import React from "react";
import PropTypes from "prop-types"
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    Brush,
    ComposedChart,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import _ from "lodash";
import "./_chart.scss";
import SaveAsExcell from "./SaveAsExcell";
import {getColorByName} from "../../../utils/ColorUtils";
import Checkbox from "../checkbox/Checkbox";
import Input from "../input/Input";
import {Fade} from "react-bootstrap";
import BasicTooltip from "../tooltip/Tooltip"
import NotFound from "../../NotFound";
import {isMobile} from "../../../utils/MobileUtils";
import {withTranslation} from "react-i18next";

export class Chart extends React.Component {

    constructor(props, context) {
        super(props, context);
        let data = this.getData(this.props) || [];
        let min = props.brush && this.calculateMinIndexForBrush(data) || 0;
        let max = props.brush && this.calculateMaxIndexForBrush(data) || 0;
        let hide = [];
        for (let [index, data] of props.dataDef.entries()) {
            if (data.defaultOff) hide.push(index)
        }
        this.state = {
            mobile: isMobile(),
            hide,
            data,
            min,
            max,
            blockBrush: false,
            brushWidth: null,
            domain: props.Yaxis.domain || this.getDefaultDomain(data, false),
            changedDomain: false
        };

        this.container = React.createRef();
        this.checkbox = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props, nextProps)) return true;
        return !_.isEqual(this.state, nextState);
    }

    componentDidMount() {
        if (this.props.brush && this.props.brush.blockBrush) {
            window.addEventListener("resize", this.resizeEventListener);
            window.dispatchEvent(new Event("resize"));
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeEventListener);
    }

    getBrushWidth() {
        if (this.container.current) {
            let width = this.container.current.clientWidth - 70;
            if (this.props.brush.blockBrush && this.checkbox.current) {
                width -= this.checkbox.current.clientWidth + 10;
            }
            return width;
        }
        return null;
    }

    resizeEventListener = () => {
        this.setState({
            brushWidth: this.getBrushWidth()
        })
    };

    onLegendItemClicked = (item) => {
        const {hasShadows, dataDef, onLegendClick} = this.props;
        const {payload: {index, dataKey}} = item;
        let hide = _.xor(this.state.hide, [index]);
        if (hasShadows) {
            let key = dataKey.trim();
            if (!dataKey.includes("Shadow")) {
                key += "Shadow";
            } else {
                key = key.split("Shadow")[0];
            }
            let shadowIndex = _.findIndex(dataDef, o => o.dataKey === key);
            if (shadowIndex >= 0) {
                hide = _.xor(hide, [shadowIndex]);
            }
        }
        onLegendClick(item);
        this.setState({
            hide
        });
    };

    getData = (props) => {
        const {data, dataDef} = props;
        let newData = _.cloneDeep(data);
        dataDef.forEach(def => {
            if (_.isFunction(def.valueConverter)) {
                newData = newData.map(o => ({
                    ...o,
                    [def.dataKey]: def.valueConverter(_.get(o, def.dataKey))
                }))
            }
        });
        return newData
    };

    isHidden = (index) => {
        return this.state.hide.includes(index);
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {data, brush, dataDef} = this.props;
        const {blockBrush, changedDomain} = this.state;
        if (!_.isEqual(data, nextProps.data)) {
            let data = this.getData(nextProps);
            let obj = {
                data
            };
            if (brush && !blockBrush) {
                obj.min = this.calculateMinIndexForBrush(data);
                obj.max = this.calculateMaxIndexForBrush(data);
            }
            obj.domain = this.getDefaultDomain(data, changedDomain)
            this.setState(obj);
        }
        if (nextProps.refreshHideOnNewChartDef && !_.isEqual(dataDef, nextProps.dataDef)) {
            console.log(dataDef, nextProps.dataDef, "chanded")
            let hide = [];
            for (let [index, data] of nextProps.dataDef.entries()) {
                if (data.defaultOff) hide.push(index)
            }
            this.setState({
                hide
            })
        }
    }

    getChartType() {
        const {type} = this.props;
        switch (type) {
            case "Area":
                return AreaChart;
            case "Bar":
                return BarChart;
            case "Composed":
                return ComposedChart;
            default:
                return AreaChart;
        }
    }

    getDataType(dataType) {
        const {type} = this.props;
        switch (type) {
            case "Area":
                return Area;
            case "Bar":
                return Bar;
            case "Composed":
                switch (dataType) {
                    case "Bar":
                        return Bar;
                    case "Area":
                        return Area;
                    default:
                        return Area;
                }
            default:
                return Area;
        }
    }

    calculateMinIndexForBrush(data) {
        const {brush: {brushKey}} = this.props;
        let min = 0;
        for (let i = 0; i < data.length - 2; i++) {
            let obj = data[i];
            let obj2 = data[i + 1];
            let obj3 = data[i + 2];
            if (obj[brushKey] && obj2[brushKey] && obj3[brushKey]) {
                min = i;
                break;
            }
        }
        return min;
    }

    calculateMaxIndexForBrush(data) {
        const {brush: {brushKey}} = this.props;
        let max = data.length - 1;
        for (let i = data.length - 1; i > 0; i--) {
            let obj = data[i];
            let obj2 = data[i - 1];
            let obj3 = data[i - 2];
            if (obj[brushKey] && obj2[brushKey] && obj3[brushKey]) {
                max = i;
                break;
            }
        }
        return max;
    }

    onCheckboxChange = value => {
        this.setState({
            blockBrush: value
        })
    };

    getMaxData(data) {
        const {dataDef} = this.props;
        let maxValue = 0;
        for (let d of data) {
            for (let def of dataDef) {
                if (d[def.dataKey] > maxValue) {
                    maxValue = d[def.dataKey];
                }
            }
        }
        return maxValue;
    }

    getDefaultDomain(data, changedDomain, isSecond = false) {
        if (changedDomain) return this.state.domain;
        const {dataDef, showDomainInput} = this.props;
        console.log(showDomainInput);
        if (showDomainInput && !isSecond) {
            let maxValue = 0;
            let valuesBelow0 = false;
            for (let d of data) {
                for (let def of dataDef) {
                    if (d[def.dataKey] > maxValue) {
                        maxValue = d[def.dataKey];
                    }
                    if (d[def.dataKey] < 0) {
                        valuesBelow0 = true;
                    }
                }
            }
            console.log(maxValue);
            maxValue = Math.ceil(maxValue);
            console.log("1");
            return [valuesBelow0 ? "dataMin - 1" : 0, maxValue + 1];
        }
        for (let d of data) {
            for (let def of dataDef) {
                if (d[def.dataKey] < 0) {
                    console.log("2");
                    return ["dataMin - 1", "dataMax + 1"];
                }
            }
        }
        console.log("3")
        return [0, "dataMax + 1"];
    }

    onBrushChange = value => {
        this.setState({
            min: value.startIndex,
            max: value.endIndex
        })
    };

    onDomainChange = value => {
        let domain = this.state.domain.slice(0);
        domain[1] = value ? +value : "dataMax + 1";
        this.setState({
            domain,
            changedDomain: true
        })
    };

    render() {
        const {
            saveAsExcell, className, dataDef, width, height, Yaxis, Xaxis, showLegend, showTooltip, tooltipFormatter,
            secondYaxis, type, brush, referenceLines, t, showDomainInput, tooltipLabelFormatter, legendHeight,
            onChartClick, tooltipContent
        } = this.props;
        const {data, min, max, blockBrush, brushWidth, domain, changedDomain, mobile} = this.state;
        console.log("domain", domain);
        if (data.length === 0) return <NotFound/>;
        return (
            <div className={"fetura-chart"} style={{width: width, height: height}} ref={this.container}>
                <ResponsiveContainer width={"100%"} height={"100%"} className={`fetura-chart-container ${className}`}>
                    {
                        React.createElement(this.getChartType(),
                            {
                                data,
                                onClick: onChartClick
                            }, [
                                <XAxis
                                    dataKey={Xaxis.dataKey}
                                    tickFormatter={Xaxis.formatter}
                                    label={{
                                        value: Xaxis.name,
                                        position: Xaxis.position || "bottom",
                                        offset: Xaxis.offset || 5
                                    }}
                                    ticks={Xaxis.ticks}
                                    domain={Xaxis.domain}
                                    type={Xaxis.type}
                                />,
                                !mobile &&
                                <YAxis domain={domain}
                                       tickFormatter={Yaxis.formatter}
                                       label={{
                                           value: Yaxis.name,
                                           angle: -90,
                                           position: Yaxis.position || "insideLeft",
                                           offset: Yaxis.offset || 5
                                       }}
                                       yAxisId={Yaxis.yAxisId || "left"} allowDataOverflow={true}
                                       ticks={Yaxis.ticks}
                                       width={Yaxis.width}
                                />,
                                secondYaxis &&
                                !mobile &&
                                <YAxis domain={secondYaxis.domain || this.getDefaultDomain(data, changedDomain, true)}
                                       tickFormatter={secondYaxis.formatter}
                                       label={{
                                           value: secondYaxis.name,
                                           angle: 90,
                                           position: secondYaxis.position || "insideRight",
                                           offset: secondYaxis.offset || 5
                                       }}
                                       orientation="right"
                                       yAxisId={secondYaxis.yAxisId || "right"}
                                       ticks={secondYaxis.ticks}
                                       width={secondYaxis.width}
                                />,
                                showLegend &&
                                <Legend onClick={this.onLegendItemClicked} iconType="circle" verticalAlign="top"
                                        height={legendHeight}/>,
                                showTooltip &&
                                <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter}
                                         content={tooltipContent}/>,
                                ...dataDef.map((def, index) =>
                                    React.createElement(this.getDataType(def.chartType),
                                        {
                                            key: index,
                                            index: index,
                                            type: def.type || "monotone",
                                            name: def.name || "",
                                            fill: getColorByName(this.isHidden(index) ? "secondary" : def.color),
                                            stroke: getColorByName(this.isHidden(index) ? "secondary" : def.color),
                                            strokeWidth: 4,
                                            fillOpacity: def.opacity || +(type === "Bar") || +(def.chartType === "Bar"),
                                            dataKey: this.isHidden(index) ? `${def.dataKey} ` : def.dataKey,
                                            connectNulls: true,
                                            strokeOpacity: def.strokeOpacity || 1,
                                            unit: def.unit,
                                            yAxisId: def.yAxisId || "left",
                                            stackId: def.stack
                                        },
                                        null
                                    )
                                ),
                                referenceLines.map((line, idx) =>
                                    <ReferenceLine key={`ref_${idx}`} x={line.x} y={line.y}
                                                   yAxisId={line.yAxisId || "left"}
                                                   ifOverflow="extendDomain"
                                                   label={{value: line.name, position: line.position || "insideTop"}}
                                                   stroke={getColorByName(line.color)} isFront/>
                                ),
                                brush &&
                                <Brush startIndex={min}
                                       endIndex={max}
                                       stroke={getColorByName("green")}
                                       dataKey={Xaxis.dataKey}
                                       width={brushWidth}
                                       onChange={this.onBrushChange}
                                       tickFormatter={brush.tickFormatter}
                                />
                            ]
                        )
                    }
                </ResponsiveContainer>
                {
                    !!saveAsExcell &&
                    <SaveAsExcell Xaxis={Xaxis} data={data} dataDef={dataDef} fileName={saveAsExcell}/>
                }
                {
                    brush && brush.blockBrush &&
                    <Checkbox label={t("basics.chart.block")} divRef={this.checkbox} value={blockBrush}
                              onChange={this.onCheckboxChange}/>
                }
                {
                    !mobile && showDomainInput &&
                    <div className="fetura-chart-domain-input">
                        <Input type={"number"} value={domain[1]} onChange={this.onDomainChange}/>
                        <Fade in={this.getMaxData(data) > domain[1]}>
                            <div>
                                <BasicTooltip tooltipContent={t("basics.chart.notShowingAll")} type={"warning"}>
                                    <i className="fas fa-exclamation-circle"/>
                                </BasicTooltip>
                            </div>
                        </Fade>
                    </div>
                }
            </div>
        );
    }

}

const colorPropCheck = (props, propName, componentName) => {
    let value = props[propName] || "";
    let arrayOfColors = ["green", "orange", "red", "pink", "blue"];
    if (!arrayOfColors.includes(value) && !value.startsWith("#")) {
        return new Error(`Invalid prop "${propName}" supplied to "${componentName}". Should have value one of ["green", "orange", "red", "pink", "blue"] or starts with #, current value ${value}`);
    }
};

Chart.propTypes = {
    dataDef: PropTypes.arrayOf(PropTypes.shape({
        // color: PropTypes.oneOf(["green", "orange", "red", "pink", "blue"]).isRequired,
        color: colorPropCheck,
        dataKey: PropTypes.string.isRequired,
        name: PropTypes.string,
        type: PropTypes.string,
        valueConverter: PropTypes.func,
        unit: PropTypes.string,
        defaultOff: PropTypes.bool,
        chartType: PropTypes.oneOf(["Area", "Bar"]), //tylko dla composed chartow
        stack: PropTypes.string
    })).isRequired,
    Xaxis: PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        formatter: PropTypes.func,
        ticks: PropTypes.array,
        domain: PropTypes.array,
        type: PropTypes.string
    }).isRequired,
    Yaxis: PropTypes.shape({
        name: PropTypes.string,
        formatter: PropTypes.func,
        domain: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        yAxisId: PropTypes.string,
        ticks: PropTypes.array,
        width: PropTypes.number,
    }).isRequired,
    type: PropTypes.oneOf(["Area", "Bar", "Composed"]),
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    data: PropTypes.array.isRequired,
    showLegend: PropTypes.bool,
    showTooltip: PropTypes.bool,
    tooltipFormatter: PropTypes.func,
    saveAsExcell: PropTypes.string,
    secondYaxis: PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatter: PropTypes.func,
        domain: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        yAxisId: PropTypes.string,
        ticks: PropTypes.array,
    }),
    brush: PropTypes.shape({
        brushKey: PropTypes.string.isRequired,
        blockBrush: PropTypes.bool,
        tickFormatter: PropTypes.func
    }),
    referenceLines: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: colorPropCheck,
        name: PropTypes.string.isRequired,
        yAxisId: PropTypes.string,
        position: PropTypes.string
    })),
    hasShadows: PropTypes.bool,
    showDomainInput: PropTypes.bool, // czy ma pokazac inputa na osi Y
    tooltipLabelFormatter: PropTypes.func,
    legendHeight: PropTypes.number,
    onLegendClick: PropTypes.func,
    onChartClick: PropTypes.func,
    refreshHideOnNewChartDef: PropTypes.bool,
    tooltipContent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

Chart.defaultProps = {
    className: "",
    refreshHideOnNewChartDef: false,
    data: [],
    dataDef: [],
    type: "Area",
    width: "100%",
    height: "100%",
    showLegend: true,
    showTooltip: true,
    saveAsExcell: "",
    referenceLines: [],
    legendHeight: 36,
    onLegendClick: () => {
    }
};

export default withTranslation()(Chart);
