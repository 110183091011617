import React from "react";
import TimeAgo from "react-timeago";
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import {connect} from "react-redux";
import _ from "lodash";

@connect((store) => {
    return {
        locale: store.language.locale,
    };
})
export default class AgoTime extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formatter: this.getFormatter(this.props)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(nextState, this.state) || !_.isEqual(nextProps, this.props);
    }

   UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.locale !== this.props.locale){
            this.setState({
                formatter: this.getFormatter(nextProps)
            })
        }
    }

    getFormatter = (props = this.props) => {
        let strings;
        const {locale} = props;
        try{
            strings = require(`react-timeago/lib/language-strings/${locale}`).default
        }catch (e) {
            strings = require(`react-timeago/lib/language-strings/en`).default
        }

        return buildFormatter(strings)
    };

    render() {
        const {formatter} = this.state;
        return (
            <>
                <TimeAgo {...this.props} formatter={formatter}/>
            </>

        )
    }
}